export const getDashboardLogo = () => {
  if (process.env.REACT_APP_DASHBOARD_TYPE === 'LACAK') return 'https://api.lacak.io/static/paas/1/logo.png'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'GMM') return 'https://gmmteletech.com/api/static/paas/1/logo.png'
}

export const getFavIconLogo = () => {
  if (process.env.REACT_APP_DASHBOARD_TYPE === 'LACAK') return 'https://api.lacak.io/static/paas/1/favicon.ico'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'GMM') return 'https://gmmteletech.com/api/static/paas/1/favicon.ico'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'CK') return 'https://aspindo-imsa.or.id/wp-content/uploads/2020/03/ck-500x375.jpg'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'SPX') return 'https://spx.co.id/favicon.ico'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'ASSA') return 'https://www.assarent.co.id/theme/default/assets/images/favicon/favicon.ico'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'JNTCARGO') return 'https://www.jtcargo.id/icons/favicon-128x128.ico'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'JNTEXPRESS') return 'https://jet.co.id/static/favicon.ico'
}