export const fetchApi = async (
  email,
  serviceFunction, 
  queryParameters = {}, 
  bodyParameters = {}, 
  auth, setAuth,
  successMessage = '',
  errorMessage = '',
) => {
  let data = null
  let snackbar = {
    variant: '',
    message: '',
  }

  if (!auth) return { data, snackbar }

  const abortController = new AbortController()

  // const temp = [
  //   { 'email': 'fms.ciptakridatama@gmail.com', 'password': '123456' }, 
  //   { 'email': 'integration.spx.track@gmail.com', 'password': '12345678' },
  //   { 'email': 'assa.rent@gmail.com', 'password': '123456' },
  //   { 'email': 'dummy@gmail.com', 'password': '123456' }
  // ]
  // const encodedTemp = encodeURIComponent(JSON.stringify(temp))
  // console.log({ encodedTemp })

  const result = await serviceFunction(
    queryParameters,
    bodyParameters,
    auth?.accessToken,
    abortController.signal,
  )

  // CASE: TOKEN IS NOT EXPIRED
  if (result?.status === 200) {
    data = result?.data
    
    snackbar.variant = 'success'
    snackbar.message = successMessage ?? 'Successfully getting the data'
  }
  // CASE: OTHER ERRORS
  else {
    snackbar.variant = 'error'
    snackbar.message = result?.data?.status?.description ?? errorMessage
  }

  abortController.abort()

  return { data, snackbar } 
}