// TO DO: REPLACE THESE LOCAL STORAGE WITH COOKIES
const keyUserProfile = 'states-user'
const keyDrawerState = 'states-drawer-state'

// USER PROFILE
export const setUserProfileToLocalStorage = (inputUserObject) => {
  localStorage.setItem(keyUserProfile, JSON.stringify(inputUserObject))
}

export const readUserProfileFromLocalStorage = () => {
  return localStorage.getItem(keyUserProfile)
    ? JSON.parse(localStorage.getItem(keyUserProfile))
    : {}
}

export const removeUserProfileFromLocalStorage = () => {
  return localStorage.removeItem(keyUserProfile)
}

export const setDrawerStateToLocalStorage = (open) => {
  localStorage.setItem(keyDrawerState, open)
}

export const getDrawerStateFromLocalStorage = () => {
  return localStorage.getItem(keyDrawerState)
}