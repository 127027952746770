//APIS
import axios from 'apis/axios'

export const searchLocation = async (
  queryParameters = {},
  bodyParameters = {},
  hash,
) => {
  try {
    const response = await axios.post(
      'geocoder/search_location', 
      { ...bodyParameters, hash },
    )
    
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    return {
      value: ''
    }
  }
}