//APIS
import axios from 'apis/axios'

export const getZoneList = async (
  queryParameters = {},
  bodyParameters = {},
  hash,
) => {
  try {
    const response = await axios.post(
      'zone/list',
      { ...bodyParameters, hash },
    )
    
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getZonePointList = async (
  queryParameters = {},
  bodyParameters = {},
  hash,
) => {
  try {
    const response = await axios.post(
      'zone/point/list',
      { ...bodyParameters, hash },
    )
    
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}