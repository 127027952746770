//APIS
import axios from 'apis/axios'

export const postSignInUser = async (
  inputSignal, 
  inputBodyParams, 
) => {
  try {
    const response = await axios.post(
      '/user/auth', 
      inputBodyParams, 
      { signal: inputSignal },
    )
  
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postRefreshToken = async (inputRefreshToken) => {
  try {
    const response = await axios.post(
      '/api/users/refresh-token',
      { refresh_token: inputRefreshToken },
    )

    return response
  }
  catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}