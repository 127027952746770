import { createContext, useCallback, useEffect, useState } from 'react'

// MUIS
import useMediaQuery from '@mui/material/useMediaQuery'

// UTILITIES
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

import { values } from 'constants/value'
import { postSignInUser } from 'services/user'

const AllPagesContext = createContext()

const AllPagesContextProvider = (props) => {
  // AUTH
  const [ auth, setAuth ] = useState(null)

  // BREAKPOINT ZOOM
  const isZoomBoundary = useMediaQuery(values.zoomBoundary)
  const isNoZoomBoundary = useMediaQuery(values.noZoomBoundary)
  // return true if in screen zoom boundary
  const breakpointZoomBoundary = (isZoomBoundary && !isNoZoomBoundary) ? isZoomBoundary : false

  //SNACKBAR
  const [ snackbarObject, setSnackbarObject ] = useState(values.initialSnackbarObject)

  //LOADING
  const [isLoading, setIsLoading] = useState(false)

  const fetchHashToken = useCallback(async () => {
    const email = window?.location?.pathname?.split('/').find(value => value.includes('@'))
    const abortController = new AbortController()
    const selectedAccount = JSON.parse(decodeURIComponent(process.env.REACT_APP_ACCOUNTS_ENCODED))?.find(item => item.email === email) || {}

    setIsLoading(true)
    const resultSignInUser = await postSignInUser(
      abortController.signal,
      {
        login: selectedAccount?.email,
        password: selectedAccount?.password,
        locale: 'en'
      },
    )
    setIsLoading(true)

    if (resultSignInUser?.status === 200) {
      const newToken = resultSignInUser?.data?.hash
      
      // UPDATE THE TOKEN
      setUserProfileToLocalStorage({
        email: email,
        accessToken: newToken,
      })

      setAuth(current => {
        return {
          ...current,
          email: email,
          accessToken: newToken,
        }
      })
    }
  }, [])

  const showSnackbar = useCallback((severity, message, title) => {
    if (severity === 'reset') {
      setSnackbarObject(prev => ({
        open: false,
        severity: prev.severity,
        title: prev.title,
        message: prev.message,
      }))
      return
    }
    setSnackbarObject(
      {
        open: true,
        severity: severity,
        title: title,
        message: message ?? '',
      }
    )
  }, [])

  useEffect(() => {
    fetchHashToken()
  }, [fetchHashToken])

  return (
    <AllPagesContext.Provider
      value={{
        // AUTH
        auth, setAuth,
        // ZOOM BOUNDARY
        breakpointZoomBoundary,
        //SNACKBAR
        snackbarObject, showSnackbar,
        //LOADING
        isLoading, setIsLoading,
      }}
    >
      {props.children}
    </AllPagesContext.Provider>
  )
}

export { AllPagesContextProvider, AllPagesContext }