import { useParams } from 'react-router-dom'

// LAYOUT
import Private from 'layouts/Private/Private'

// UTILITIES
import { isObjectEmpty } from 'utilities/validation'

function PrivateRoute(props) {
  const { children } = props
  const { email } = useParams()

  return isObjectEmpty(email) ? null : (
    <Private>{children}</Private>
  )
}

export default PrivateRoute
