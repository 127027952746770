import { types } from 'constants/geofence'
import { getZoneList, getZonePointList } from 'services/zone'
import { fetchApi } from 'utilities/api'

export const getProcessedZoneList = async (
  email,
  inputBodyParams,
  auth, setAuth
) => {
  try {
    const { list: zones } = (await fetchApi(
      email,
      getZoneList, 
      {},
      inputBodyParams,
      auth, setAuth,
    ))?.data

    const polygons = []
    const circles = []

    await Promise.all(zones.map(async (zone) => {
      const { type } = zone
      if(type === types.CIRCLE) {
        circles.push(zone)
      }
      else {
        const { list: detail } = (await fetchApi(
          email,
          getZonePointList, 
          {},
          {
            ...inputBodyParams,
            zone_id: zone.id
          },
          auth, setAuth,
        ))?.data
        
        polygons.push(detail)
      }
    }))

    return [...polygons, ...circles]
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}