import { startCase } from 'lodash'

// MUIS
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

// MUI ICONS
import IconLocalParking from '@mui/icons-material/LocalParking'
import IconNavigation from '@mui/icons-material/Navigation'
import IconStop from '@mui/icons-material/Stop'

// UTILITIES
import { getConnectionStatusColor } from 'utilities/component'
import { flattenObject } from 'utilities/objects'

export const restructureVehicleList = (vehicleList) => {
  return vehicleList.map((item) => {
    const { state, group } = item
    const { gps, gsm, inputs, outputs } = state || {}
  
    const groupObject = flattenObject(group, 'group_')
    const stateObject = flattenObject(state, 'state_')
    const gpsObject = flattenObject(gps, 'state_gps_')
    const gsmObject = flattenObject(gsm, 'state_gsm_')
    const inputObject = inputs?.reduce((obj, curr, idx) => ({
      ...obj,
      [`input_${idx}`]: curr
    }), {})
    const outputObject = outputs?.reduce((obj, curr, idx) => ({
      ...obj,
      [`output_${idx}`]: curr
    }), {})

    return {
      ...item,
      ...groupObject,
      ...stateObject,
      ...gpsObject,
      ...gsmObject,
      ...inputObject,
      ...outputObject,
      state_gps_movement_status: stateObject.state_movement_status,
      state_gps_connection_status: stateObject.state_connection_status,
      state_gps_location: gpsObject.state_gps_location ? `${gpsObject.state_gps_location.lat}, ${gpsObject.state_gps_location.lng}` : '',
      address: {
        isLoading: true,
        value: '-'
      }
    }
  })
}

export const initialFilters = {
  group: '',
  label: '',
  connection_status: '',
  movement_status: '',
  odometer: '',
  odometer_update: '',
  engine_hours: '',
  engine_hours_update: '',
  gps_address: '',
  gps_location: '',
  gps_signal_level: '',
  gps_update: '',
  heading: '',
  speed: '',
  altitude: '',
  gsm_update: '',
  gsm_signal_level: '',
  gsm_network_name: '',
  battery_level: '',
  battery_update: '',
  input_1: '',
  input_2: '',
  input_3: '',
  input_4: '',
  input_5: '',
  inputs_update: '',
  output_1: '',
  output_2: '',
  output_3: '',
  output_4: '',
  outputs_update: '',
}

export const getInitialColumns = (classes, handleTriggerButtonClick) => {
  return [
    {
      field: 'group_title',
      headerName: 'Group',
      minWidth: 250,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'label',
      headerName: 'Label',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    process.env.REACT_APP_DASHBOARD_TYPE === 'ASSA' &&
    {
      field: 'box',
      headerName: 'Check/Open Box',
      minWidth: 160,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      renderCell: (params) => (
        <Stack
          direction='row'
          spacing={1}
        >
          <Button 
            variant='contained'
            color='success'
            size='small'
            onClick={event => handleTriggerButtonClick(event, params.row, 'setdigout 101 1 1 1')}
          >
            Check
          </Button>

          <Button 
            variant='contained'
            color='warning'
            size='small'
            onClick={event => handleTriggerButtonClick(event, params.row, 'setdigout 110 1 1 1')}
          >
            Open
          </Button>
        </Stack>
      )
    },
    process.env.REACT_APP_DASHBOARD_TYPE === 'ASSA' &&
    {
      field: 'door',
      headerName: 'Lock/Unlock Door',
      minWidth: 160,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      renderCell: (params) => (
        <Stack
          direction='row'
          spacing={1}
        >
          <Button 
            variant='contained'
            color='success'
            size='small'
            onClick={event => handleTriggerButtonClick(event, params.row, 'setdigout 010 1 1 1')}
          >
            Lock
          </Button>

          <Button 
            variant='contained'
            color='warning'
            size='small'
            onClick={event => handleTriggerButtonClick(event, params.row, 'setdigout 011 1 1 1')}
          >
            Unlock
          </Button>
        </Stack>
      )
    },
    process.env.REACT_APP_DASHBOARD_TYPE === 'ASSA' &&
    {
      field: 'immo',
      headerName: 'Lock/Unlock Immo',
      minWidth: 160,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      renderCell: (params) => (
        <Stack
          direction='row'
          spacing={1}
        >
          <Button 
            variant='contained'
            color='success'
            size='small'
            onClick={event => handleTriggerButtonClick(event, params.row, 'setdigout 001 1 1 1')}
          >
            Lock
          </Button>

          <Button 
            variant='contained'
            color='warning'
            size='small'
            onClick={event => handleTriggerButtonClick(event, params.row, 'setdigout 100 1 1 1')}
          >
            Unlock
          </Button>
        </Stack>
      )
    },
    process.env.REACT_APP_DASHBOARD_TYPE === 'ASSA' &&
    {
      field: 'none',
      headerName: 'None',
      minWidth: 80,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      renderCell: (params) => (
        <Button 
          variant='contained'
          color='info'
          size='small'
          onClick={event => handleTriggerButtonClick(event, params.row, 'setdigout 000')}
        >
          None
        </Button>
      )
    },
    {
      field: 'state_gps_connection_status',
      headerName: 'Connection Status',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Chip label={startCase(params.value.replace('_', ' '))} size='small' color={getConnectionStatusColor(params.value.replace('_', ' ').toUpperCase())} sx={{borderRadius: 1}}/>
        ),
    },
    {
      field: 'state_gps_movement_status',
      headerName: 'Movement Status',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Stack direction={'row'} alignItems='center'>
            {params.value.toUpperCase() === 'MOVING' && <IconNavigation className={classes.iconStatus} sx={(theme) => ({bgcolor: theme.palette.success.main})} />}
            {params.value.toUpperCase() === 'PARKED' && <IconLocalParking className={classes.iconStatus} sx={(theme) => ({bgcolor: theme.palette.primary.main})} />}
            {params.value.toUpperCase() === 'STOPPED' && <IconStop className={classes.iconStatus} sx={(theme) => ({bgcolor: theme.palette.error.main})} />}
            &nbsp;
            <Typography 
              variant='inherit'
              className='textCapitalize'
            >
              {params.value.replace('_', ' ')}
            </Typography>
          </Stack>
        ),
    },
    {
      field: 'odometer',
      headerName: 'Odometer',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'engine_hours',
      headerName: 'Engine Hours',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'address',
      headerName: 'GPS Address',
      minWidth: 250,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
      renderCell: (params) => {
        return (
          <Stack width='100%'>
            {params?.value?.isLoading
              ? <LinearProgress />
              : <Typography variant='caption' noWrap>{params?.value?.value}</Typography>
            }
          </Stack>
        )
      }
    },
    {
      field: 'state_gps_location',
      headerName: 'GPS Location',
      minWidth: 250,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_signal_level',
      headerName: 'GPS Signal Level',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_updated',
      headerName: 'GPS Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_heading',
      headerName: 'Heading',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_speed',
      headerName: 'Speed',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_alt',
      headerName: 'Altitude',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gsm_updated',
      headerName: 'GSM Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gsm_signal_level',
      headerName: 'GSM Signal Level',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gsm_network_name',
      headerName: 'GSM Network Name',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_battery_level',
      headerName: 'Battery Level',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_battery_update',
      headerName: 'Battery Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_1',
      headerName: 'Input 1',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_2',
      headerName: 'Input 2',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_3',
      headerName: 'Input 3',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_4',
      headerName: 'Input 4',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_5',
      headerName: 'Input 5',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_inputs_update',
      headerName: 'Inputs Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_1',
      headerName: 'Output 1',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_2',
      headerName: 'Output 2',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_3',
      headerName: 'Output 3',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_4',
      headerName: 'Output 4',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_outputs_update',
      headerName: 'Outputs Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'lastUpdate',
      headerName: 'Last Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
      valueGetter: params => params?.row?.state?.last_update
    },
  ].filter(item => item)
}