import { useState, useContext, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

//COMPONENT
import AppBar from 'components/AppBar/AppBar'
import DataGridTable from 'components/Customs/CustomDataGrid'
import Flyout from 'components/Flyout/Flyout'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'
import Map from 'components/Map/Map'
// import VehicleDropdown from './VehicleDropdown/VehicleDropdown'

// CONSTANTS
import { colors } from 'constants/colors'
import { values } from 'constants/value'
import { initialFilters, getInitialColumns, restructureVehicleList } from './vehicleConstants'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import { useGridApiRef } from '@mui/x-data-grid-premium'

// MUI ICONS
import IconDownload from '@mui/icons-material/Download'

// SERVICES
import { searchLocation } from 'services/geocoder'
import { postSendCommand } from 'services/tracker'

// STYLES
import useLayoutStyles from './vehiclesUseStyles'

// UTILS
import { fetchApi } from 'utilities/api'
import { getCombinedTrackerData } from 'utilities/tracker'
import { getProcessedZoneList } from 'utilities/zone'
import { downloadTableData } from './vehiclesUtils'

const Vehicles = () => {
  const classes = useLayoutStyles()
  const { email } = useParams()

  // CONTEXT
  const { 
    auth, setAuth,
    breakpointZoomBoundary, 
    setIsLoading,
    showSnackbar
  } = useContext(AllPagesContext)

  // REFS
  const apiRef = useGridApiRef()

  // LOADING
  const [ isDataGridLoading, setIsDataGridLoading ] = useState(false)

  const handleTriggerButtonClick = async (event, row, command) => {
    event.stopPropagation()
    
    setIsDataGridLoading(true)

    const abortController = new AbortController()

    const result = await fetchApi(
      email,
      postSendCommand, 
      {},
      {
        tracker_id: row.id,
        command,
      },
      auth, setAuth,
    )

    abortController.abort()
    
    if (result?.data?.success === true) showSnackbar('success', 'Success to trigger the device')
    else if (result?.data?.errors) showSnackbar('error', 'Failed to trigger the device')

    setIsDataGridLoading(false)
  }

  // APP BAR
  const [pageSearch, setPageSearch] = useState('')
  // FLYOUT
  const [isFlyoutShown, setIsFlyoutShown] = useState(false)
  // DATA GRID - BASE
  const [ selectedColumnList, setSelectedColumnList ] = useState(getInitialColumns(classes, handleTriggerButtonClick))
  const [ tableData, setTableData ] = useState([])
  // DATA GRID - PAGINATION
  const [ totalRow, setTotalRow ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(500)
  // DATA GRID - ORDER
  const [ order, setOrder ] = useState(null)
  const [ orderBy, setOrderBy ] = useState(null)
  // DATA GRID - FILTER
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ filters, setFilters ] = useState(initialFilters)
  // DATA GRID - SELECTION
  const [ selectionModel, setSelectionModel ] = useState([])
  // DIALOG
  const [showDialog, setShowDialog] = useState(false)
  // MAPS
  const [selectedCoordinates, setSelectedCoordinates] = useState(tableData)
  const [center, setCenter] = useState(null)
  const [zones, setZones] = useState([])
  const [isGeofenceLoading, setIsGeofenceLoading] = useState(false)
  // LOCATION
  const [ isStartGetLocation, setIsStartGetLocation ] = useState(false)


  const fetchVehicleList = useCallback(async (isMounted) => {
    setIsDataGridLoading(true)

    try {
      const response = await getCombinedTrackerData(
        email, 
        {}, 
        true, 
        colors.marker.default, 
        setIsLoading,
        auth, setAuth,
      )

      if (response?.status !== 'failed' && isMounted) {
        const restructureData = restructureVehicleList(response)
        setTableData(restructureData)
        setSelectedCoordinates(restructureData)
        setIsStartGetLocation(true)
      }
    } catch (error) {
      setTableData([])
      setSelectedCoordinates([])
    }

    setIsLoading(false)
    setIsDataGridLoading(false)
  }, [email, setIsLoading, auth, setAuth])

  const fetchZoneList = useCallback(async (isMounted) => {
    const result = await getProcessedZoneList(
      email,
      {
        sort:['label=asc'],
        offset:0,
        limit:500,
      },
      auth, setAuth
    )
    
    if(isMounted) {
      setZones(result)
      setIsGeofenceLoading(true)
    }
  }, [auth, email, setAuth])

  const handleCloseDialog = () => setShowDialog(false)
  
  const handleRowSelection = (currRow) => {
    const currRowData = tableData.find((v) => v.id === currRow[0])
    setCenter([currRowData.state_gps?.location?.lat, currRowData.state_gps?.location?.lng])
    setIsFlyoutShown(true)
  }

  const fetchVehiclesLocation = useCallback(async () => {
    for (let item of tableData) {
      const addressValue = await fetchApi(
        email,
        searchLocation, 
        {}, 
        { location: item.state.gps.location }, 
        auth, setAuth,
      )

      if (apiRef.current) apiRef.current.updateRows([
        {
          id: item.id,
          address: {
            isLoading: false,
            value: addressValue?.data?.value ?? '-'
          }
        }
      ])
    }
  }, [ tableData, auth, setAuth, email, apiRef ])

  // SIDE EFFECT FETCHING DATA 
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    fetchVehicleList(isMounted)
    fetchZoneList(isMounted)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [email, filters, pageSearch, order, orderBy, fetchVehicleList, fetchZoneList])

  useEffect(() => {
    if (isStartGetLocation && tableData?.length) {
      fetchVehiclesLocation()
    }
  }, [ isStartGetLocation, tableData, fetchVehiclesLocation ])

  useEffect(() => {
    if (selectionModel.length === 1) setIsFlyoutShown(true)
    else setIsFlyoutShown(false)
  }, [selectionModel])

  return (
    <>
      <AppBar
        className={'zoom'}
        pageTitle='Vehicles'
        hasSearch={false}
        // search={pageSearch}
        // setSearch={setPageSearch}
        hasFlyout={false}
        isFlyoutShown={isFlyoutShown}
        flyoutTitle='Vehicle Info'
        flyoutTitleMargin={breakpointZoomBoundary ? 300 : 252}
        onToggleFlyoutClick={() => setIsFlyoutShown((current) => !current)}
        extraComponent={
          <Button
            variant='contained'
            startIcon={<IconDownload color='inherit'/>}
            sx={{ color: theme => theme.palette.common.white }}
            onClick={() => downloadTableData(tableData)}
            color='success'
            disabled={isDataGridLoading}
          >
            Download CSV
          </Button>
        }
      />

      <Stack
        direction='row'
        position='relative'
        flex='1'
        className='contentContainer'
        sx={{
          paddingRight: isFlyoutShown ? `${values.flyoutWidth + 24}px` : 0,
          height: '100vh',
        }}
      >
        {/* MAIN CONTENT */}
        <LoadingPaper isLoading={isDataGridLoading} className={`${classes.tableContainer}`}>
          {/* <VehicleDropdown
            label='Refresh Table'
            value={refreshRate}
            handleChange={(e) => {
              setRefreshRate(e.target.value)
            }}
            options={refreshOptions}
          /> */}

          <DataGridTable
            // BASE
            apiRef={apiRef}
            columns={getInitialColumns(classes, handleTriggerButtonClick)}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            rows={tableData}
            throttleRowsMs={2000}
            // getRowId={(row) => row.id}
            // PAGINATION
            total={totalRow}
            paginationModel={{ page: pageNumber, pageSize }}
            onPaginationModelChange={(model, details) => {
              setPageNumber(model.page)
              setPageSize(model.pageSize)
            }}
            // ORDER
            setOrder={setOrder}
            order={order}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            // FILTER
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            onRowSelectionModelChange={handleRowSelection}
            checkboxSelection={false}
          />
        </LoadingPaper>

        {/* SIDE CONTENT */}
        <Flyout isFlyoutShown={isFlyoutShown} flyoutWidth={values.flyoutWidth} className={`${classes.vehicleFlyout}`}>
          <Map
            zones={zones}
            isGeofenceLoading={isGeofenceLoading}
            selectedCenter={center} 
            isWithFullscreen={true}
            handleFullscreenClick={() => setShowDialog(true)}
            coordinates={selectedCoordinates.map(v => {
              return {
                coordinate: [v.state_gps?.location?.lat, v.state_gps?.location?.lng],
                radius: v.state_gps_signal_level < 50 ? null : v.state_gps_signal_level,
                label: v.label,
                color: v.group.color ?? v.group_color
              }
            })}
          />
        </Flyout>
      </Stack>

      <Dialog open={showDialog} onClose={handleCloseDialog} fullWidth={true} maxWidth='lg' keepMounted>
        <Stack className={`${classes.dialogContainer}`} direction='column'>
          <Map
            zones={zones}
            isGeofenceLoading={isGeofenceLoading}
            selectedCenter={center} 
            isWithFullscreen={false}
            handleFullscreenClick={() => setShowDialog(true)}
            coordinates={selectedCoordinates.map(v => ({
              coordinate: [v.state_gps?.location?.lat, v.state_gps?.location?.lng],
              radius: v.state_gps_signal_level < 50 ? null : v.state_gps_signal_level,
              label: v.label,
              color: v.group.color ?? v.group_color
            }))}
          />
          <Button variant='text' onClick={handleCloseDialog} className={`${classes.closeButton}`}>Close</Button>
        </Stack>
      </Dialog>
    </>
  )
}

export default Vehicles
